import React from 'react';

import style from './style.module.scss';
import Utils from 'library/Utils';

class TitleValLayout extends React.Component{
    constructor(props) {
        super(props);

        if ((typeof this.props.keyVals) != "object"){
            throw new Error('Input data must be object');
        }

        this.state = {
            "smallScreen": false
        }

        this.updateSizing = this.updateSizing.bind(this);
    }

    componentDidMount() {
        if (!this.props.smallScreenThresholdEM){
            return
        }

        this.updateSizing();
        window.addEventListener('resize', this.updateSizing);
    }
    componentWillUnmount() {
        if (!this.props.smallScreenThresholdEM){
            return
        }

        window.removeEventListener('resize', this.updateSizing);
    }

    updateSizing(){
        this.setState({
            smallScreen: (Utils.getScreenWidthEM() < this.props.smallScreenThresholdEM)
        })
    }

    render(){
        let rows = [];
        for (const title in this.props.keyVals) {
            if (!this.props.keyVals.hasOwnProperty(title)) {
                continue;
            }

            let val = this.props.keyVals[title];

            if ((typeof val) == "string"){
                val = <p>{val}</p>;
            }

            let titleStyle = {};
            let contentStyle = {};
            if (!this.state.smallScreen){
                titleStyle.width = this.props.titleColWidthEM+"em";
                contentStyle.width = this.props.contentColWidthEM+"em";
            }

            rows.push(
                <div className={style.row} key={title}>
                    <div className={style.title} style={titleStyle}>
                        <h4>{title}</h4>
                    </div>
                    <div className={style.rowContent} style={contentStyle}>
                        {val}
                    </div>
                </div>
            );
        }

        let contStyle = style.container;
        if (this.state.smallScreen){
            contStyle += " "+style.smallScreen;
        }

        return(
            <div className={contStyle}>
                {rows}
            </div>
        );
    }
}

export default TitleValLayout;