import React from 'react';

import DataDriver from 'library/DataDriver';
import APIRequest from 'library/Request';

import { ContentPage } from 'components/ContentPage';
import { AnyQuestions } from 'components/AnyQuestions';

import './cookie-policy.scss';

import { Button } from 'components/Button';
import TitleValLayout from 'components/TitleValLayout';
import IndyLink from 'components/IndyLink';
import { Asterisk } from 'components/Asterisk';

class CookiePolicyPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            "DD:optedOutOf": []
        }

        this.togglePubTrack = this.togglePubTrack.bind(this);
    }

    componentDidMount(){
        this.listenerID = DataDriver.attachListenerForStateChange(this, ["optedOutOf"]);
    }
    componentWillUnmount() {
        DataDriver.removeListener(this.listenerID);
    }

    togglePubTrack(){
        let isOptOut = !this.state["DD:optedOutOf"].includes("PubTrack");
        let action;
        if (isOptOut){
            action = "OptOut";
        }else{
            action = "OptIn";
        }

        try {
            let req = new APIRequest("changeCookiePref/", {
                type: "PubTrack",
                action: action
            },{
                requestType: "POST"
            });
        
            req.makeRequest().then(function(){
                DataDriver.refresh("optedOutOf");
            });
        } catch (errorMsg) {
            console.error('Opt-in preference change failure: '+errorMsg);
        }
    }

    render(){
        let tableLayoutTitleWidth = 6.6;
        let tableLayoutContentWidth = 30;
        let tableLayoutSmallScreenThresholdEM = (tableLayoutContentWidth + 3 + tableLayoutTitleWidth) / 0.8;

        return(
            <ContentPage Title="Cookie Policy" path="/cookie-policy">
                <div id="cookiePolIntro">
                    <p>We are proud of our cookie policy's simplicity. <strong>We don't implement any third party tracking,</strong> which means we can tell you exactly what each cookie does, why and the options you have in opting-out.</p>

                    <p>All our cookies are first party<Asterisk hashAnchor="/cookie-policy#stripe"/>. This means they are set by us, only intended to be viewed by us and only have information useful to us. We send them securely to keep them as safe and private as possible.</p>
                </div>

                <h2>Public Traffic Tracker</h2>
                <div className="cookieDetailsWrap">
                    <TitleValLayout 
                        keyVals={{
                            "Cookie name": "MashoomFrontSessionID",
                            "Lifetime": "1 year",
                            "Domain": "www.mashoom.co.uk/api/front/",
                            "Example value": "SIgqjm8bhG37cMefMNww"
                        }}
                        titleColWidthEM = {tableLayoutTitleWidth}
                        contentColWidthEM = {tableLayoutContentWidth}
                        smallScreenThresholdEM = {tableLayoutSmallScreenThresholdEM}
                    />
                    <div className="cookieExplanation">
                        <p>This cookie allows us to track how many unique visitors we have viewing our site. We assign your browser a random identifier that is sent each time you view or use our site.</p>
                        <p>If you don't want us to do this, click the button below:</p>
                        <Button type="UIAction" onClick={this.togglePubTrack} title={!this.state["DD:optedOutOf"].includes("PubTrack") ? (
                            "Disable this cookie"
                        ) : (
                            "Enable this cookie"
                        )} />
                    </div>
                </div>

                <h2>User Session</h2>
                <div className="cookieDetailsWrap">
                    <TitleValLayout 
                        keyVals={{
                            "Cookie name": "MashoomUserSessionID",
                            "Lifetime": "\"Session\"; expires when you close your browser or if it hasn't been used in 15 minutes",
                            "Domain": "www.mashoom.co.uk/user/",
                            "Example value": "TapYceZH1Ax%2CDWH78d4ChrWthb"
                        }}
                        titleColWidthEM = {tableLayoutTitleWidth}
                        contentColWidthEM = {tableLayoutContentWidth}
                        smallScreenThresholdEM = {tableLayoutSmallScreenThresholdEM}
                    />
                    <div className="cookieExplanation">
                        <p>This cookie allows us to identify you once you have logged into Mashoom. This cookie essentially is a temporary password to verify it's you making each request.</p>
                        <p>This is the best method for keeping your session and data safe, so you must accept this cookie to log into Mashoom.</p>
                    </div>
                </div>

                <h2>Remember Me</h2>
                <div className="cookieDetailsWrap">
                    <TitleValLayout 
                        keyVals={{
                            "Cookie name": "MSHM_RM_V1",
                            "Lifetime": "90 days",
                            "Domain": "www.mashoom.co.uk/user/",
                            "Example value": "D3y78cxXIe_%7C_yhr3OPgUTyGHu43QzC4jklBYR9AETq128PDDIEn8"
                        }}
                        titleColWidthEM = {tableLayoutTitleWidth}
                        contentColWidthEM = {tableLayoutContentWidth}
                        smallScreenThresholdEM = {tableLayoutSmallScreenThresholdEM}
                    />
                    <div className="cookieExplanation">
                        <p>This cookie allows us to keep you logged in, even if you close your browser or aren't active for a while. This cookie works much in the same way as a session cookie, but we also validate this cookie using a few other methods to keep it as safe as possible.</p>
                        <p>This is the best method to remember you, so to use this features you must accept this cookie.</p>
                    </div>
                </div>

                <h2>Opt-out</h2>
                <div className="cookieDetailsWrap">
                    <TitleValLayout 
                        keyVals={{
                            "Cookie name": "MashoomOptOut",
                            "Lifetime": "1 year",
                            "Domain": "www.mashoom.co.uk/",
                            "Example value": "PubTrack"
                        }}
                        titleColWidthEM = {tableLayoutTitleWidth}
                        contentColWidthEM = {tableLayoutContentWidth}
                        smallScreenThresholdEM = {tableLayoutSmallScreenThresholdEM}
                    />
                    <div className="cookieExplanation">
                        <p>This cookie tells us if and which cookies you have opted out of. It contains no unique data so can't be used to track you in itself.</p>
                    </div>
                </div>

                <h2 id="stripe">Stripe</h2>
                <p>Mashoom uses Stripe as our payment provider. In order to provide fraud protection and keep your payment details secure Stripe sets a range of cookies. From Mashoom's perspective, these are third party cookies.</p>
                <p>Details of these cookies and what they are used for <IndyLink adr="https://stripe.com/cookies-policy/legal">can be found here</IndyLink>.</p>

                <AnyQuestions />
            </ContentPage>
        );
    }
}

export default CookiePolicyPage;